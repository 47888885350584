(function($) {

  // window.showGeoModal = window.showGeoModal || false;

  let settings = null;

  //

  window.addEventListener('lightbox:open', (e) => {
    let el = e.detail.el;

    if ([...el.classList].includes('js-geo_modal_handle')) {
      onOpenModal();
    }
  });

  window.addEventListener('lightbox:close', (e) => {
    let el = e.detail.el;

    if ([...el.classList].includes('js-geo_modal_handle')) {
      onCloseModal();
    }
  });

  //

  function getCookie(name) {
    let c = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return (c && c.length) ? c.pop() : '';
  }

  function setCookie(name, value, days) {
    let expires = '';

    if (settings.country == 'JP') {
      days = 0.16666; // 4 hours for jp
    }

    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }

    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }

  function getRedirectUrl(id) {
    let url = DOLE_GEO.sites[ id ].url;

    // if (id !== 'US') {
    //   url += '?geo=true';
    // }

    return url;
  }

  function updateSettings() {
    setCookie('dole_geo_settings', JSON.stringify(settings), 7); // moved to a week to avoid showing the popup over and over
  }

  function openModal() {
    setTimeout(() => {
      document.querySelectorAll('.js-geo_modal_handle')[0].click();
    }, 1);
  }

  function onOpenModal() {
    // document.querySelectorAll('.js-geo_modal')[0].classList.add('visible');
  }

  function onCloseModal() {
    // document.querySelectorAll('.js-geo_modal')[0].classList.remove('visible');

    // settings.no_redirect = true;
    settings.show_modal = false;
    updateSettings();
  }

  function initModal() {
    // let close = document.querySelectorAll('.js-geo_close')[0];
    // let triggers = document.querySelectorAll('.js-geo_modal_handle');
    let lang = (typeof DOLE_GEO.languages[ DOLE_GEO.language ] == 'undefined') ? 'en' : DOLE_GEO.language;

    document.querySelectorAll('.js-geo_modal .geo_modal_title')[0].innerHTML = DOLE_GEO.languages[lang].title;
    document.querySelectorAll('.js-geo_modal .geo_modal_content p')[0].innerHTML = DOLE_GEO.languages[lang].content;

    // for (let i = 0; i < triggers.length; i++) {
    //   triggers[i].classList.add('geo_ready');
    //   triggers[i].addEventListener('click', function(e) {
    //     e.preventDefault();
    //     e.stopPropagation();

    //     onOpenModal();
    //   }, false);
    // }

    // close.addEventListener('click', onCloseModal, false);
  }

  function handle() {
    let params = (new URL(document.location)).searchParams;
    let show_modal = (params.get('geo') == 'true');
    let no_redirect = (params.get('redirect') == 'false') || settings.no_redirect;
    let redirect_url = null;
    let skip = false;

    if (no_redirect) {
      settings.no_redirect = true;
    }

    // Always Redirect to JP if in JP, unless no_redirect is set
    if (DOLE_GEO.current != 8 && settings.country == 'JP' && !settings.no_redirect) {
      redirect_url = DOLE_GEO.sites['JP'].url;
    } else {

      // // Skip NEW US
      // if (DOLE_GEO.current == 14) {
      //   skip = true;
      // }

      // Skip DSI
      if (DOLE_GEO.current == 5) {
        skip = true;
      }

      // Skip CA Food Service
      if (DOLE_GEO.current == 13) {
        skip = true;
      }

      // Skip if we've redirected or dont want to
      if (settings.no_redirect /* || settings.did_redirect */) {
        skip = true;
      }

      if (!skip) {
        // If we do not have a region site, head to global
        if (typeof DOLE_GEO.sites[ settings.country ] == 'undefined') {
          // if (DOLE_GEO.current != 1) { //
          if (DOLE_GEO.current != 14) { // New us
            // redirect_url = '/us/';
            redirect_url = '/us/?geo=true';
          }
        } else if (settings.country == 'BE') {

          // CUSTOM BE
          if (DOLE_GEO.current != DOLE_GEO.sites[ settings.country ].id) {
            redirect_url = getRedirectUrl( settings.country );
          }

          if (settings.region != 'Wallonia' && DOLE_GEO.language != 'nl') { // NL
            redirect_url = getRedirectUrl( settings.country ).replace('/fr', '/nl');
          }

          if (settings.region == 'Wallonia' && DOLE_GEO.language != 'fr') { // FR
            redirect_url = getRedirectUrl( settings.country ).replace('/nl', '/fr');
          }

          // END CUSTOM BE

        } else if (DOLE_GEO.current != DOLE_GEO.sites[ settings.country ].id) {
          redirect_url = getRedirectUrl( settings.country );

          // // If we have a region site but we're not on it display the modal
          // if (document.readyState !== 'loading') {
          //   onOpenModal();
          // } else {
          //   document.addEventListener('DOMContentLoaded', onOpenModal);
          // }
        }
      }
    }

    //

    updateSettings();

    //

    if (redirect_url) {
      console.log('Redirect to: ' + redirect_url);

      $('body').append('<div class="geo_overlay"><div class="geo_content"><span class="geo_spinner"></span><p>' + DOLE_GEO.message + '</p></div></div>');
      window.location = redirect_url;
    } else {
      console.log('No redirect');
    }

    //

    if (show_modal && settings.show_modal) {
      // window.showGeoModal = true;

      // Show modal if we redirected from another site?
      if (document.readyState !== 'loading') {
        openModal();
      } else {
        document.addEventListener('DOMContentLoaded', openModal);
        // window.showGeoModal = true;
      }
    }
  }

  //

  if (document.readyState !== 'loading') {
    initModal();
  } else {
    document.addEventListener('DOMContentLoaded', initModal);
  }

  //

  try {
    settings = JSON.parse(decodeURIComponent(getCookie('dole_geo_settings')));
  } catch(e) {}

  if (settings) {
    handle();
  } else {
    $.ajax({
      // url: 'https://privacyauthenticator.com/country?v=' + DOLE_GEO.version,
      url: 'https://privacyauthenticator.com/location?v=' + DOLE_GEO.version,
      method: 'get',
      dataType: 'json',
      success: (response, jqXHR) => {
        settings = $.extend(response, {
          'no_redirect': false,
          // 'did_redirect': false,
          'show_modal': true,
        });

        // settings.country = 'BE';
        // settings.region = 'Not';
        // settings.region = 'Wallonia';

        handle();
      }
    });
  }

})(jQuery);